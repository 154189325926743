import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";
import {css} from '@emotion/react';


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const AkademieImages = (props) => {
  const data = useStaticQuery(graphql`
    query {
      akademie: file(relativePath: { eq: "header/akademie_header.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      
      akademie_01: file(relativePath: { eq: "grafiken/02_Ansatz_04_Selbstverantwortlich_gesch.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      akademie_02: file(relativePath: { eq: "grafiken/03_Beratung_03_Nah.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      akademie_03: file(relativePath: { eq: "grafiken/02_Ansatz_03_Netzwerk.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      akademie_04: file(relativePath: { eq: "grafiken/03_Beratung_01_Ebenen.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, grayscale: false, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
    } 
  `)
  
  if (props.image === "akademie")  return  <Img alt="Proceed Akademie Header" fluid={data.akademie.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "akademie_01") return <Img alt="Proceed Akademie Abendveranstaltungen" fluid={data.akademie_01.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "akademie_02") return <Img alt="Proceed Akademie Tagesseminare" fluid={data.akademie_02.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "akademie_03") return <Img alt="Proceed Akademie Online Events" fluid={data.akademie_03.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "akademie_04") return <Img alt="Proceed Akademie Wissen" fluid={data.akademie_04.childImageSharp.fluid} objectFit="cover" css={css`height: 100%; width: 100%;`} />
  
  else return null
}

export default AkademieImages
