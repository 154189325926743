import React, { useState } from "react";
import {Wrapper, dark, orange, white, FlexContainer, FlexBox, FlexBoxRow, akademieselector, background2, Container, siteheader } from "../styles/globalstyles";
import SEO from "../components/seo"
import { motion } from "framer-motion";
import AkademieImages from "../components/images-akademie";
import { MediumOverline } from "../components/overline";
import Icons from '../components/icons';
import {AkademieEvents, AkademieEvents2} from "../components/events/event-sorter";
import {css} from '@emotion/react';


const selector ={
  active: {
    color: white,
    background: orange,
    transition: {duration: 0.3}
  },
  inactive: {
    color: dark,
    background: white,
    transition: {duration: 0.3}
  }

}

const Akademie = (props) => {
  const [siteState, setSiteState] = useState("event");
  return (
    <>
    <Wrapper background={background2}>
      <Container>
        <SEO 
        title="Akademie - Bringen Sie Ihr Unternehmen auf den Weg" 
        description="Sie wollen Ihr Unternehmen auf den Weg bringen. Das Know-how dafür vermitteln Ihnen die Seminare der Proceed-Akademie. Sie decken alle Themen ab, die Ihnen während einer Transformation begegnen." 
        pictureUrl={props.pageContext.contextData.metaImages[0].akademie.url} 
        />
        
      <FlexContainer direction="column" justify="center" align="flex-start" css={[siteheader,{overflow: "visible", paddingBottom: 0, }]}>
          <FlexBox direction="column" css={css`width: 100%; overflow: visible;  @media (min-width: 768px) {width: 80%}`}>
          <MediumOverline>Die Proceed-Akademie</MediumOverline>
          <h1> Bringen Sie Ihr Unternehmen auf den Weg</h1>
          <h5>Weg von Hierarchie, Abteilungsdenken und schleppenden Abläufen. Hin zu Teamarbeit, flexiblen Strukturen und intelligenten Prozessen. Ziel ist eine anpassungsfähige Organisation, in der Menschen gerne und gemeinsam arbeiten.</h5>
          </FlexBox>
       
      </FlexContainer>
      </Container>
  </Wrapper>
  <Wrapper background={background2}>
    <Container>
      <FlexContainer direction="column" justify="space-eventy" css={{ paddingBottom: "2.4rem", paddingTop: "2.4rem"}}>
        <FlexBoxRow css={css`width: 100%; overflow: visible; flex-wrap: wrap; display: none;`}>
          <motion.div whileHover={{scale: 1.01}} className="buttonShadow" initial="inactive" variants={selector} animate={siteState ==="event" ? "active" : "inactive"} onClick={() => setSiteState("event")} css={akademieselector}> 
          <div css={css`height: 2.8em; width: 2.8em;`}> 
            <Icons icon="puzzle" />
          </div>
            <div css={css`margin-left: 0.6em; margin-block-end: 0 !important;`}>
                <h4 css={css`color: inherit; margin-bottom: 0;`}>Abendveranstaltungen</h4>
            </div>
          </motion.div>
          <motion.div whileHover={{scale: 1.01}} className="buttonShadow" initial="inactive" variants={selector} animate={siteState ==="seminar" ? "active" : "inactive"}  onClick={() => setSiteState("seminar")} css={akademieselector}> 
          <div css={css`height: 2.8em; width: 2.8em;`}> 
            <Icons icon="board" />
          </div>
            <div css={css`margin-left: 0.6em; margin-block-end: 0 !important;`}>
                <h4 css={css`color: inherit; margin-bottom: 0;`}>Tagesseminare</h4>
            </div>
          </motion.div>
          <motion.div whileHover={{scale: 1.01}} className="buttonShadow" initial="inactive" variants={selector} animate={siteState ==="onlineevent" ? "active" : "inactive"} onClick={() => setSiteState("onlineevent")} css={akademieselector}> 
          <div css={css`height: 2.8em; width: 2.8em;`}> 
            <Icons icon="model" />
          </div>
            <div css={css`margin-left: 0.6em; margin-block-end: 0 !important;`}>
                <h4 css={css`color: inherit; margin-bottom: 0;`}>Online-Events</h4>
            </div>
          </motion.div>
          <motion.div whileHover={{scale: 1.01}} css={{display: "none"}}> 
          <div css={css`width: 100%; height: 10em; padding: 1em;`}> 
            <AkademieImages image="akademie_04"  />
            </div>
            <div css={css`width: 100%; margin-block-end: 0 !important;padding: 0 0.6em; height: 8em;`}>
                <h4 css={css`color: inherit;`}>Wissen</h4>
                <p css={css`margin-bottom: 0 !important; font-size: 0.78em !important;`}>Veranstaltungen mit komprimierten, kurzen Informationen</p>
            </div>
          </motion.div>
        </FlexBoxRow>
      </FlexContainer>
    </Container>
  </Wrapper>
  
  <Wrapper background={background2}>
    <Container>
      <AkademieEvents2 state={siteState}/>
    </Container>
  </Wrapper>
</>
)
}
export default Akademie
