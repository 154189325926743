import styled from "@emotion/styled";

export const SmallOverline = styled.h6 ({
    marginBottom: "1em",
    fontSize: "1em",
})

export const MediumOverline = styled.h6 ({
    marginBottom: "1.2em",
    fontSize: "1.25em"
})

export const LargeOverline = styled.h6 ({
    marginBottom: "1.4em",
    fontSize: "1.6em"
})